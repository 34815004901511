import React from "react";

import Style from "./Style";

const Header = () => {
  return (
    <Style>
      <div className="container">
        <div className="navbar d-flex justify-content-between align-items-center">
          <div className="logo">
            <div className="">Nemo</div>
          </div>
          <div className="menu">
            <ul className="menu-list">
              <li className="menu-item">Home</li>
              <li className="menu-item">Demo</li>
              <li className="menu-item">Document</li>
              <li className="menu-item">Futures</li>
              <li className="menu-item">Support</li>
            </ul>
          </div>
          <div className="">
            <button className="buy-btn">Buy Now</button>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default Header;
