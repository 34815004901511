import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout/index";
import Seo from "../components/Seo";

import Home from "../components/Home";

const IndexPage = () => (
  <div>
    <Layout>
      <Seo title="Home" />
      <Home />
    </Layout>
  </div>
);

export default IndexPage;
