import React from "react";

import Style from "./Style";

export default function index() {
  return (
    <Style>
      <div className="">
        <div className="main-banner">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="main-banner-content">
                  <span class="typewrite" data-period="2000" data-type='[ "" ]'>
                    Built with Fabricjs, ReactJS, Antd .
                  </span>
                  <h1>Rewy - Gatsby React IT Startup Template</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="product">Home Pages</div>
      </div>
    </Style>
  );
}
