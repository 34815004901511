import styled from "styled-components";

const Style = styled.div`
  margin-top: 78px;

  .main-banner-content {
    text-align: center;
  }

  .d-table {
    width: 100%;
    height: 100%;
  }

  .d-table-cell {
    vertical-align: middle;
  }

  .product {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
  }

  @media only screen and (min-width: 1650px) {
    .main-banner {
      height: 650px;
    }
  }
`;

export default Style;
