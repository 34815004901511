import styled from "styled-components";

const Style = styled.div`
  box-shadow: 0 2px 8px rgb(221 221 221 / 50%);

  background-color: #fff;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-transition: 0.6s;
  transition: 0.6s;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  .container {
    max-width: 1120px;
  }

  .logo {
    display: flex;
    font-weight: 700;
  }

  .navbar {
    height: 48px;
    line-height: 8px;
  }

  .menu-list {
    display: flex;
    list-style: none;
    justify-content: space-around;
    height: 32px;
    margin: 0;
    padding: 0;
    line-height: 32px;
  }

  .menu-item {
    padding: 0 20px;
    font-weight: 500;
  }

  .buy-btn {
    background-color: #ff5d22;
    color: #fff;
    padding: 16px 25px;
    border-radius: 30px;
    font-size: 16px;
    display: inline-block;
    font-weight: 700;
    margin-left: 10px;

    border: none;
  }
`;

export default Style;
